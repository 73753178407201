import { NgClass, NgStyle } from '@angular/common';
import * as i0 from '@angular/core';
import { input, output, computed, Component, ChangeDetectionStrategy, HostBinding, Pipe } from '@angular/core';
import { CrmUnsubscribeDirective, crmResolveExpression, crmKillEvent, CrmElementIdPipe, CrmDisabledDirective, CrmMergeObjectsPipe } from 'common-module/core';
import { crmResolveRenderItem, CrmRenderer2Directive } from 'common-module/renderer';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { switchMap } from 'rxjs/operators';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { compact } from 'lodash-es';
import { switchMap as switchMap$1, combineLatest, map, of, startWith } from 'rxjs';
import { NzDropDownDirective, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { NzMenuDirective, NzMenuItemComponent } from 'ng-zorro-antd/menu';

/**
 * Action buttons set that render given header action item as button
 * or as plain a button item e.g. for dropdown lists.
 */
function CrmActionButtonComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 0);
  }
  if (rf & 2) {
    const item_r1 = ctx;
    i0.ɵɵproperty("crmRenderer2", item_r1.content)("context", item_r1.contentContext);
  }
}
function CrmActionButtonComponent_Conditional_1_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "i", 3);
  }
  if (rf & 2) {
    i0.ɵɵproperty("nzType", ctx);
  }
}
function CrmActionButtonComponent_Conditional_1_Conditional_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "crmTranslate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, ctx));
  }
}
function CrmActionButtonComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 2);
    i0.ɵɵpipe(1, "crmElementId");
    i0.ɵɵpipe(2, "crmTranslate");
    i0.ɵɵpipe(3, "crmTranslate");
    i0.ɵɵlistener("click", function CrmActionButtonComponent_Conditional_1_Template_button_click_0_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.handleActionClick($event));
    });
    i0.ɵɵtemplate(4, CrmActionButtonComponent_Conditional_1_Conditional_4_Template, 1, 1, "i", 3)(5, CrmActionButtonComponent_Conditional_1_Conditional_5_Template, 3, 3, "span");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_13_0;
    let tmp_14_0;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("id", i0.ɵɵpipeBind2(1, 14, ctx_r2.view().id, "action-button"))("ngClass", ctx_r2.view().className)("ngStyle", ctx_r2.view().style)("nzType", ctx_r2.view().type)("nzDanger", ctx_r2.view().danger)("nzSize", ctx_r2.view().size)("nzBlock", ctx_r2.view().block)("isDisabled", ctx_r2.isDisabled())("disabledTooltip", i0.ɵɵpipeBind1(2, 17, ctx_r2.view().disabledTooltip))("enabledTooltip", i0.ɵɵpipeBind1(3, 19, ctx_r2.view().tooltip))("tooltipPlacement", ctx_r2.view().tooltipPlacement)("nzLoading", ctx_r2.isLoading());
    i0.ɵɵadvance(4);
    i0.ɵɵconditional((tmp_13_0 = ctx_r2.view().icon) ? 4 : -1, tmp_13_0);
    i0.ɵɵadvance();
    i0.ɵɵconditional((tmp_14_0 = ctx_r2.view().title) ? 5 : -1, tmp_14_0);
  }
}
const _forTrack0 = ($index, $item) => $item.id;
const _c0 = () => ({
  type: "link",
  size: "small"
});
function CrmDropdownButtonComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "i", 2);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("nzType", ctx)("nzRotate", ctx_r1.view().iconRotate);
  }
}
function CrmDropdownButtonComponent_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "crmTranslate");
  }
  if (rf & 2) {
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, ctx), " ");
  }
}
function CrmDropdownButtonComponent_For_8_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 4);
    i0.ɵɵpipe(1, "crmElementId");
    i0.ɵɵelementStart(2, "crm-action-button", 5);
    i0.ɵɵpipe(3, "crmMergeObjects");
    i0.ɵɵlistener("actionClicked", function CrmDropdownButtonComponent_For_8_Template_crm_action_button_actionClicked_2_listener() {
      const action_r4 = i0.ɵɵrestoreView(_r3).$implicit;
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.handleActionClick(action_r4));
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const action_r4 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("id", i0.ɵɵpipeBind2(1, 3, action_r4.id, "detailHeader_actions"));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("data", ctx_r1.data())("action", i0.ɵɵpipeBind2(3, 6, i0.ɵɵpureFunction0(9, _c0), action_r4));
  }
}
function CrmActionButtonsComponent_For_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "crm-action-button", 2);
    i0.ɵɵlistener("actionClicked", function CrmActionButtonsComponent_For_1_Template_crm_action_button_actionClicked_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.handleActionClick($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const action_r3 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngStyle", action_r3.actionStyle)("action", action_r3)("data", ctx_r1.data());
  }
}
function CrmActionButtonsComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "crm-dropdown-button", 3);
    i0.ɵɵpipe(1, "crmElementId");
    i0.ɵɵlistener("actionClicked", function CrmActionButtonsComponent_Conditional_2_Template_crm_dropdown_button_actionClicked_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.handleActionClick($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("id", i0.ɵɵpipeBind2(1, 12, "more_dropdown", "action-buttons"))("actions", ctx_r1.resolvedActions().dropdown)("moreText", ctx_r1.dropdown().moreText)("moreIcon", ctx_r1.dropdown().moreIcon)("moreIconRotate", ctx_r1.dropdown().moreIconRotate)("isDisabled", ctx_r1.dropdown().isDisabled)("disabledTooltip", ctx_r1.dropdown().disabledTooltip)("type", ctx_r1.dropdown().type)("size", ctx_r1.dropdown().size)("dropdownTrigger", ctx_r1.dropdown().dropdownTrigger)("buttonStyle", ctx_r1.dropdown().buttonStyle)("data", ctx_r1.dropdown().data);
  }
}
class CrmActionButtonComponent extends CrmUnsubscribeDirective {
  constructor() {
    super(...arguments);
    this.action = input.required();
    this.data = input();
    this.actionClicked = output();
    this.hostClass = 'crm-action-button';
    this.view = computed(() => {
      const {
        content,
        contentContext,
        type,
        size,
        ...action
      } = this.action();
      return {
        render: crmResolveRenderItem({
          content,
          contentContext
        }),
        type: type ?? 'default',
        size: size ?? 'large',
        ...action
      };
    });
    this.isDisabled$ = toObservable(computed(() => ({
      resolvable: this.action().isDisabled$ ?? false,
      source: this.data()
    }))).pipe(switchMap(({
      resolvable,
      source
    }) => {
      return crmResolveExpression({
        resolvable,
        source,
        injector: this.injector
      });
    }));
    this.isDisabled = toSignal(this.isDisabled$, {
      initialValue: false
    });
    this.isLoading$ = toObservable(computed(() => ({
      resolvable: this.action().isLoading$ ?? false,
      source: this.data()
    }))).pipe(switchMap(({
      resolvable,
      source
    }) => {
      return crmResolveExpression({
        resolvable,
        source,
        injector: this.injector
      });
    }));
    this.isLoading = toSignal(this.isLoading$, {
      initialValue: false
    });
  }
  handleActionClick(event) {
    if (this.isDisabled() || this.isLoading()) {
      crmKillEvent(event);
      return;
    }
    this.actionClicked.emit(this.action());
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmActionButtonComponent_BaseFactory;
      return function CrmActionButtonComponent_Factory(__ngFactoryType__) {
        return (ɵCrmActionButtonComponent_BaseFactory || (ɵCrmActionButtonComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmActionButtonComponent)))(__ngFactoryType__ || CrmActionButtonComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmActionButtonComponent,
      selectors: [["crm-action-button"]],
      hostVars: 2,
      hostBindings: function CrmActionButtonComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        action: [1, "action"],
        data: [1, "data"]
      },
      outputs: {
        actionClicked: "actionClicked"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 1,
      consts: [[3, "crmRenderer2", "context"], ["nz-button", "", "crmDisabled", "", 3, "id", "ngClass", "ngStyle", "nzType", "nzDanger", "nzSize", "nzBlock", "isDisabled", "disabledTooltip", "enabledTooltip", "tooltipPlacement", "nzLoading"], ["nz-button", "", "crmDisabled", "", 3, "click", "id", "ngClass", "ngStyle", "nzType", "nzDanger", "nzSize", "nzBlock", "isDisabled", "disabledTooltip", "enabledTooltip", "tooltipPlacement", "nzLoading"], ["nz-icon", "", 3, "nzType"]],
      template: function CrmActionButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, CrmActionButtonComponent_Conditional_0_Template, 1, 2, "ng-container", 0)(1, CrmActionButtonComponent_Conditional_1_Template, 6, 21, "button", 1);
        }
        if (rf & 2) {
          let tmp_0_0;
          i0.ɵɵconditional((tmp_0_0 = ctx.view().render) ? 0 : 1, tmp_0_0);
        }
      },
      dependencies: [CrmTranslatePipe, NgClass, NgStyle, CrmElementIdPipe, NzButtonComponent, CrmDisabledDirective, NzIconDirective, CrmRenderer2Directive],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmActionButtonComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-action-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CrmTranslatePipe, NgClass, NgStyle, CrmElementIdPipe, NzButtonComponent, CrmDisabledDirective, NzIconDirective, CrmRenderer2Directive],
      template: "@if (view().render; as item) {\r\n  <ng-container\r\n    [crmRenderer2]=\"item.content\"\r\n    [context]=\"item.contentContext\"\r\n  ></ng-container>\r\n} @else {\r\n  <button\r\n    nz-button\r\n    crmDisabled\r\n    [id]=\"view().id | crmElementId: 'action-button'\"\r\n    [ngClass]=\"view().className\"\r\n    [ngStyle]=\"view().style\"\r\n    [nzType]=\"view().type\"\r\n    [nzDanger]=\"view().danger\"\r\n    [nzSize]=\"view().size\"\r\n    [nzBlock]=\"view().block\"\r\n    [isDisabled]=\"isDisabled()\"\r\n    [disabledTooltip]=\"view().disabledTooltip | crmTranslate\"\r\n    [enabledTooltip]=\"view().tooltip | crmTranslate\"\r\n    [tooltipPlacement]=\"view().tooltipPlacement\"\r\n    [nzLoading]=\"isLoading()\"\r\n    (click)=\"handleActionClick($event)\"\r\n  >\r\n    @if (view().icon; as icon) {\r\n      <i nz-icon [nzType]=\"icon\"></i>\r\n    }\r\n\r\n    @if (view().title; as title) {\r\n      <span>{{ title | crmTranslate }}</span>\r\n    }\r\n  </button>\r\n}\r\n"
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
const crmResolveAction = (action, data) => {
  if (!action.action) {
    return false;
  }
  if (data != null) {
    action.action(data);
  } else {
    action.action();
  }
  return true;
};
class CrmDropdownButtonComponent extends CrmUnsubscribeDirective {
  constructor() {
    super(...arguments);
    this.actions = input.required();
    this.id = input();
    this.moreText = input();
    this.moreIcon = input();
    this.moreIconRotate = input();
    this.overlayClassName = input();
    this.isDisabled = input();
    this.disabledTooltip = input();
    this.type = input();
    this.size = input();
    this.dropdownTrigger = input();
    this.buttonStyle = input();
    this.data = input();
    this.actionClicked = output();
    this.hostClass = 'crm-dropdown-button';
    this.isDisabled$ = toObservable(computed(() => ({
      resolvable: this.isDisabled() ?? false,
      source: this.data()
    }))).pipe(switchMap(({
      resolvable,
      source
    }) => {
      return crmResolveExpression({
        resolvable,
        source,
        injector: this.injector
      });
    }));
    this.resolvedDisabled = toSignal(this.isDisabled$, {
      initialValue: false
    });
    this.resolvedMoreIcon = computed(() => {
      const moreIcon = this.moreIcon();
      const moreText = this.moreText();
      if (!moreIcon && !moreText) {
        return 'ellipsis';
      }
      return moreIcon;
    });
    this.view = computed(() => {
      return {
        id: this.id(),
        type: this.type() ?? 'default',
        size: this.size() ?? 'large',
        buttonStyle: this.buttonStyle() ?? {},
        trigger: this.dropdownTrigger() ?? 'click',
        className: this.overlayClassName() ?? '',
        isDisabled: this.resolvedDisabled(),
        tooltip: this.disabledTooltip(),
        icon: this.resolvedMoreIcon(),
        iconRotate: this.moreIconRotate() ?? 0,
        text: this.moreText(),
        actions: this.actions()
      };
    });
  }
  handleDropdownClick(ev) {
    crmKillEvent(ev);
  }
  handleActionClick(action) {
    if (this.actionClicked['listeners']?.length) {
      this.actionClicked.emit(action);
      return;
    }
    crmResolveAction(action, this.data());
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmDropdownButtonComponent_BaseFactory;
      return function CrmDropdownButtonComponent_Factory(__ngFactoryType__) {
        return (ɵCrmDropdownButtonComponent_BaseFactory || (ɵCrmDropdownButtonComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmDropdownButtonComponent)))(__ngFactoryType__ || CrmDropdownButtonComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmDropdownButtonComponent,
      selectors: [["crm-dropdown-button"]],
      hostVars: 2,
      hostBindings: function CrmDropdownButtonComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        actions: [1, "actions"],
        id: [1, "id"],
        moreText: [1, "moreText"],
        moreIcon: [1, "moreIcon"],
        moreIconRotate: [1, "moreIconRotate"],
        overlayClassName: [1, "overlayClassName"],
        isDisabled: [1, "isDisabled"],
        disabledTooltip: [1, "disabledTooltip"],
        type: [1, "type"],
        size: [1, "size"],
        dropdownTrigger: [1, "dropdownTrigger"],
        buttonStyle: [1, "buttonStyle"],
        data: [1, "data"]
      },
      outputs: {
        actionClicked: "actionClicked"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 9,
      vars: 13,
      consts: [["menu1", "nzDropdownMenu"], ["nz-button", "", "nz-dropdown", "", "crmDisabled", "", "nzPlacement", "bottomRight", 3, "click", "id", "nzType", "nzSize", "ngStyle", "nzTrigger", "nzDropdownMenu", "nzOverlayClassName", "isDisabled", "disabledTooltip"], ["nz-icon", "", 3, "nzType", "nzRotate"], ["nz-menu", "", 1, "crm-dropdown-button__menu"], ["nz-menu-item", "", 1, "crm-dropdown-button__menu__item", 3, "id"], [3, "actionClicked", "data", "action"]],
      template: function CrmDropdownButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "button", 1);
          i0.ɵɵpipe(1, "crmTranslate");
          i0.ɵɵlistener("click", function CrmDropdownButtonComponent_Template_button_click_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.handleDropdownClick($event));
          });
          i0.ɵɵtemplate(2, CrmDropdownButtonComponent_Conditional_2_Template, 1, 2, "i", 2)(3, CrmDropdownButtonComponent_Conditional_3_Template, 2, 3);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(4, "nz-dropdown-menu", null, 0)(6, "ul", 3);
          i0.ɵɵrepeaterCreate(7, CrmDropdownButtonComponent_For_8_Template, 4, 10, "li", 4, _forTrack0);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          let tmp_10_0;
          let tmp_11_0;
          const menu1_r5 = i0.ɵɵreference(5);
          i0.ɵɵproperty("id", ctx.view().id)("nzType", ctx.view().type)("nzSize", ctx.view().size)("ngStyle", ctx.view().buttonStyle)("nzTrigger", ctx.view().trigger)("nzDropdownMenu", menu1_r5)("nzOverlayClassName", ctx.view().className)("isDisabled", ctx.view().isDisabled)("disabledTooltip", i0.ɵɵpipeBind1(1, 11, ctx.view().tooltip));
          i0.ɵɵadvance(2);
          i0.ɵɵconditional((tmp_10_0 = ctx.view().icon) ? 2 : -1, tmp_10_0);
          i0.ɵɵadvance();
          i0.ɵɵconditional((tmp_11_0 = ctx.view().text) ? 3 : -1, tmp_11_0);
          i0.ɵɵadvance(4);
          i0.ɵɵrepeater(ctx.view().actions);
        }
      },
      dependencies: [CrmActionButtonComponent, CrmTranslatePipe, NzButtonComponent, NgStyle, NzDropDownDirective, CrmDisabledDirective, NzIconDirective, NzDropdownMenuComponent, CrmElementIdPipe, NzMenuDirective, NzMenuItemComponent, CrmMergeObjectsPipe],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmDropdownButtonComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-dropdown-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CrmActionButtonComponent, CrmTranslatePipe, NzButtonComponent, NgStyle, NzDropDownDirective, CrmDisabledDirective, NzIconDirective, NzDropdownMenuComponent, CrmElementIdPipe, NzMenuDirective, NzMenuItemComponent, CrmMergeObjectsPipe],
      template: "<button\r\n  [id]=\"view().id\"\r\n  nz-button\r\n  nz-dropdown\r\n  crmDisabled\r\n  [nzType]=\"view().type\"\r\n  [nzSize]=\"view().size\"\r\n  [ngStyle]=\"view().buttonStyle\"\r\n  [nzTrigger]=\"view().trigger\"\r\n  [nzDropdownMenu]=\"menu1\"\r\n  [nzOverlayClassName]=\"view().className\"\r\n  nzPlacement=\"bottomRight\"\r\n  [isDisabled]=\"view().isDisabled\"\r\n  [disabledTooltip]=\"view().tooltip | crmTranslate\"\r\n  (click)=\"handleDropdownClick($event)\"\r\n>\r\n  @if (view().icon; as icon) {\r\n    <i nz-icon [nzType]=\"icon\" [nzRotate]=\"view().iconRotate\"></i>\r\n  }\r\n\r\n  @if (view().text; as text) {\r\n    {{ text | crmTranslate }}\r\n  }\r\n</button>\r\n\r\n<nz-dropdown-menu #menu1=\"nzDropdownMenu\">\r\n  <ul nz-menu class=\"crm-dropdown-button__menu\">\r\n    @for (action of view().actions; track action.id) {\r\n      <li\r\n        nz-menu-item\r\n        class=\"crm-dropdown-button__menu__item\"\r\n        [id]=\"action.id | crmElementId: 'detailHeader_actions'\"\r\n      >\r\n        <crm-action-button\r\n          [data]=\"data()\"\r\n          [action]=\"{ type: 'link', size: 'small' } | crmMergeObjects: action\"\r\n          (actionClicked)=\"handleActionClick(action)\"\r\n        ></crm-action-button>\r\n      </li>\r\n    }\r\n  </ul>\r\n</nz-dropdown-menu>\r\n"
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Component to show HeaderActions as ActionButtonComponents with a simple logic of displaying only selected
 * count of a buttons for an actions and hiding rest in a dropdown menu.
 */
class CrmActionButtonsComponent extends CrmUnsubscribeDirective {
  constructor() {
    super(...arguments);
    /**
     * Array of the HeaderAction objects to be rendered as action buttons.
     */
    this.actions = input.required();
    /**
     * A number indicating how many elements from start of array will be displayed.
     * Default value is 2
     */
    this.separateActionsCount = input();
    /**
     * Data for buttons
     */
    this.data = input();
    /**
     * Dropdown button config
     */
    this.dropdownConfig = input();
    /**
     * Output emitting in case click event was fired on action button
     */
    this.actionClicked = output();
    this.hostClass = 'crm-action-buttons';
    this.resolvedActions$ = toObservable(computed(() => ({
      actions: this.actions(),
      data: this.data(),
      count: this.count()
    }))).pipe(switchMap$1(({
      count,
      actions,
      data
    }) => {
      const actions$ = actions.map(action => this.resolveActionIsShown(action, data));
      return combineLatest(actions$).pipe(map(shownActions => ({
        actions: compact(shownActions),
        count
      })));
    }), map(({
      actions,
      count
    }) => ({
      inline: actions.slice(0, count),
      dropdown: actions.slice(count)
    })));
    this.resolvedActions = toSignal(this.resolvedActions$, {
      initialValue: {
        inline: [],
        dropdown: []
      }
    });
    this.dropdown = computed(() => {
      const config = this.dropdownConfig() ?? {};
      return {
        ...config,
        data: config.data ?? this.data()
      };
    });
    this.count = computed(() => {
      return this.separateActionsCount() ?? 2;
    });
  }
  /**
   * Handler for action click
   *
   * @param action
   */
  handleActionClick(action) {
    if (crmResolveAction(action, this.data())) {
      return;
    }
    this.actionClicked.emit(action);
  }
  resolveActionIsShown(action, source) {
    if (action.isShown$ == null) {
      return of(action);
    }
    return crmResolveExpression({
      resolvable: action.isShown$,
      source
    }).pipe(startWith(false), map(isShown => isShown ? action : undefined));
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmActionButtonsComponent_BaseFactory;
      return function CrmActionButtonsComponent_Factory(__ngFactoryType__) {
        return (ɵCrmActionButtonsComponent_BaseFactory || (ɵCrmActionButtonsComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmActionButtonsComponent)))(__ngFactoryType__ || CrmActionButtonsComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmActionButtonsComponent,
      selectors: [["crm-action-buttons"]],
      hostVars: 2,
      hostBindings: function CrmActionButtonsComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        actions: [1, "actions"],
        separateActionsCount: [1, "separateActionsCount"],
        data: [1, "data"],
        dropdownConfig: [1, "dropdownConfig"]
      },
      outputs: {
        actionClicked: "actionClicked"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 3,
      vars: 1,
      consts: [[3, "ngStyle", "action", "data"], ["overlayClassName", "crm-action-buttons__dropdown", 3, "id", "actions", "moreText", "moreIcon", "moreIconRotate", "isDisabled", "disabledTooltip", "type", "size", "dropdownTrigger", "buttonStyle", "data"], [3, "actionClicked", "ngStyle", "action", "data"], ["overlayClassName", "crm-action-buttons__dropdown", 3, "actionClicked", "id", "actions", "moreText", "moreIcon", "moreIconRotate", "isDisabled", "disabledTooltip", "type", "size", "dropdownTrigger", "buttonStyle", "data"]],
      template: function CrmActionButtonsComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵrepeaterCreate(0, CrmActionButtonsComponent_For_1_Template, 1, 3, "crm-action-button", 0, _forTrack0);
          i0.ɵɵtemplate(2, CrmActionButtonsComponent_Conditional_2_Template, 2, 15, "crm-dropdown-button", 1);
        }
        if (rf & 2) {
          i0.ɵɵrepeater(ctx.resolvedActions().inline);
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(ctx.resolvedActions().dropdown.length ? 2 : -1);
        }
      },
      dependencies: [CrmActionButtonComponent, CrmDropdownButtonComponent, NgStyle, CrmElementIdPipe],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmActionButtonsComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-action-buttons',
      imports: [CrmActionButtonComponent, CrmDropdownButtonComponent, NgStyle, CrmElementIdPipe],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "@for (action of resolvedActions().inline; track action.id) {\r\n  <crm-action-button\r\n    [ngStyle]=\"action.actionStyle\"\r\n    [action]=\"action\"\r\n    [data]=\"data()\"\r\n    (actionClicked)=\"handleActionClick($event)\"\r\n  ></crm-action-button>\r\n}\r\n\r\n@if (resolvedActions().dropdown.length) {\r\n  <crm-dropdown-button\r\n    [id]=\"'more_dropdown' | crmElementId: 'action-buttons'\"\r\n    overlayClassName=\"crm-action-buttons__dropdown\"\r\n    [actions]=\"resolvedActions().dropdown\"\r\n    [moreText]=\"dropdown().moreText\"\r\n    [moreIcon]=\"dropdown().moreIcon\"\r\n    [moreIconRotate]=\"dropdown().moreIconRotate\"\r\n    [isDisabled]=\"dropdown().isDisabled\"\r\n    [disabledTooltip]=\"dropdown().disabledTooltip\"\r\n    [type]=\"dropdown().type\"\r\n    [size]=\"dropdown().size\"\r\n    [dropdownTrigger]=\"dropdown().dropdownTrigger\"\r\n    [buttonStyle]=\"dropdown().buttonStyle\"\r\n    [data]=\"dropdown().data\"\r\n    (actionClicked)=\"handleActionClick($event)\"\r\n  ></crm-dropdown-button>\r\n}\r\n"
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class CrmAsActionButton {
  transform(value) {
    return value;
  }
  static {
    this.ɵfac = function CrmAsActionButton_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CrmAsActionButton)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmAsActionButton",
      type: CrmAsActionButton,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmAsActionButton, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmAsActionButton'
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CrmActionButtonComponent, CrmActionButtonsComponent, CrmAsActionButton, CrmDropdownButtonComponent, crmResolveAction };
