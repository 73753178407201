import { Injectable } from '@angular/core';
import { CrmEndpointDecorator } from 'common-module/endpoint';
import { CrmUserService } from 'common-module/user';

import { UserModel } from './user.model';
import { UserEndpoint } from './user.endpoint';
import { UserResponseTransformer } from './user-response.transformer';

@Injectable({ providedIn: 'root' })
export class UserService extends CrmUserService<UserModel> {
  @CrmEndpointDecorator({
    configName: 'crm',
    endpointName: 'users',
    endpoint: UserEndpoint,
    responseTransformer: UserResponseTransformer,
  })
  protected override endpoint!: UserEndpoint;
}
