import * as i0 from '@angular/core';
import { inject, Component, ChangeDetectionStrategy, Directive, signal, ViewContainerRef, HostBinding, Injectable, createEnvironmentInjector, EnvironmentInjector, NgModule } from '@angular/core';
import { CrmRenderer2Directive } from 'common-module/renderer';
import { NZ_MODAL_DATA, NzModalRef, NzModalFooterDirective, NzModalService } from 'ng-zorro-antd/modal';
import { CrmActionButtonsComponent } from 'common-module/buttons';
import { CrmLoadingComponent } from 'common-module/common';
import { CrmFormComponent, crmValidateForm } from 'common-module/form';
import { BehaviorSubject, Subject, of, EMPTY } from 'rxjs';
import { map, switchMap, takeUntil, filter, take } from 'rxjs/operators';
import { CrmOnceSubject } from 'common-module/core';
import { CrmTranslateService } from 'common-module/translate';
function CrmModalFormComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "crm-loading");
  }
}
function CrmModalFormComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "crm-form", 0);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("provider", ctx_r0.formProvider)("data", ctx_r0.data == null ? null : ctx_r0.data.data);
  }
}
function CrmModalFormComponent_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "crm-action-buttons", 2);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    let tmp_2_0;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("actions", ctx_r0.actionsConfig.actions)("separateActionsCount", (tmp_2_0 = ctx_r0.actionsConfig.separateActionsCount) !== null && tmp_2_0 !== undefined ? tmp_2_0 : 2)("dropdownConfig", ctx_r0.actionsConfig.dropdownConfig);
  }
}
class CrmModalComponent {
  constructor() {
    this.modalData = inject(NZ_MODAL_DATA);
  }
  ngOnInit() {
    this.renderItem = this.modalData.renderItem;
  }
  static {
    this.ɵfac = function CrmModalComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CrmModalComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmModalComponent,
      selectors: [["crm-modal"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 2,
      consts: [[3, "crmRenderer2", "context"]],
      template: function CrmModalComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("crmRenderer2", ctx.renderItem.content)("context", ctx.renderItem.contentContext);
        }
      },
      dependencies: [CrmRenderer2Directive],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmModalComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-modal',
      template: `<div
    [crmRenderer2]="renderItem.content"
    [context]="renderItem.contentContext"
  ></div>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CrmRenderer2Directive]
    }]
  }], null, null);
})();
class CrmModalRefDirective {
  constructor() {
    this.modalRef = inject(NzModalRef);
    this.data = inject(NZ_MODAL_DATA);
  }
  submit(data) {
    this.result = data;
    this.modalRef.close({
      success: true,
      data
    });
  }
  dismiss() {
    this.modalRef.destroy({
      success: false
    });
  }
  static {
    this.ɵfac = function CrmModalRefDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CrmModalRefDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: CrmModalRefDirective
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmModalRefDirective, [{
    type: Directive
  }], null, null);
})();
class CrmModalFormComponent extends CrmModalRefDirective {
  constructor() {
    super(...arguments);
    this.hostClass = 'crm-modal-form';
    this.isLoading = signal(true);
    this.viewContainerRef = inject(ViewContainerRef);
  }
  ngOnInit() {
    if (!this.data) {
      throw new Error(`No injectData for ${this.constructor.name}!`);
    }
    this.provider = this.data.injector.get(this.data.provider);
    if (!this.provider) {
      throw new Error(`No provider for ${this.constructor.name}`);
    }
    this.provider.initModalConfig({
      data: this.data.data,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      modalRef: this.modalRef,
      viewContainerRef: this.viewContainerRef
    }).subscribe(({
      formProvider,
      actionsConfig
    }) => {
      this.formProvider = formProvider;
      this.actionsConfig = actionsConfig;
      this.isLoading.set(false);
    });
  }
  ngOnDestroy() {
    this.provider?.destroy();
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmModalFormComponent_BaseFactory;
      return function CrmModalFormComponent_Factory(__ngFactoryType__) {
        return (ɵCrmModalFormComponent_BaseFactory || (ɵCrmModalFormComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmModalFormComponent)))(__ngFactoryType__ || CrmModalFormComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmModalFormComponent,
      selectors: [["crm-modal-form"]],
      hostVars: 2,
      hostBindings: function CrmModalFormComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 3,
      vars: 1,
      consts: [[3, "provider", "data"], [4, "nzModalFooter"], [3, "actions", "separateActionsCount", "dropdownConfig"]],
      template: function CrmModalFormComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, CrmModalFormComponent_Conditional_0_Template, 1, 0, "crm-loading")(1, CrmModalFormComponent_Conditional_1_Template, 1, 2, "crm-form", 0)(2, CrmModalFormComponent_ng_container_2_Template, 2, 3, "ng-container", 1);
        }
        if (rf & 2) {
          i0.ɵɵconditional(ctx.isLoading() ? 0 : 1);
        }
      },
      dependencies: [CrmFormComponent, CrmActionButtonsComponent, NzModalFooterDirective, CrmLoadingComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmModalFormComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-modal-form',
      template: `
    @if (isLoading()) {
      <crm-loading></crm-loading>
    } @else {
      <crm-form [provider]="formProvider" [data]="data?.data"></crm-form>
    }

    <ng-container *nzModalFooter>
      <crm-action-buttons
        [actions]="actionsConfig.actions"
        [separateActionsCount]="actionsConfig.separateActionsCount ?? 2"
        [dropdownConfig]="actionsConfig.dropdownConfig"
      ></crm-action-buttons>
    </ng-container>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CrmFormComponent, CrmActionButtonsComponent, NzModalFooterDirective, CrmLoadingComponent]
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class CrmModalFormProvider {
  constructor() {
    this.saveTitle = 'crm.modal.actions.save';
    this.cancelTitle = 'crm.modal.actions.cancel';
    this.separateActions = 2;
    this._isLoading$ = new BehaviorSubject(false);
    this.isDisabled$ = new BehaviorSubject(true);
    this.destroy$ = new Subject();
  }
  get isLoading$() {
    return this._isLoading$.asObservable();
  }
  initModalConfig(options) {
    const {
      data,
      modalRef,
      viewContainerRef
    } = options;
    this.data = data;
    this.modalRef = modalRef;
    this.viewContainerRef = viewContainerRef;
    this.subscribeFormChanges();
    return this.getActionsConfig().pipe(map(actionsConfig => ({
      actionsConfig,
      formProvider: this.formProvider
    })));
  }
  destroy() {
    this.destroy$.next();
  }
  subscribeFormChanges() {
    this.formProvider.initialized$.pipe(switchMap(({
      form
    }) => form.valueChanges.pipe(map(() => this.mapIsDisabledAction(form)))), takeUntil(this.destroy$)).subscribe(disabled => this.isDisabled$.next(disabled));
  }
  mapIsDisabledAction(form) {
    return form.pristine || form.disabled;
  }
  getActionsConfig() {
    return of({
      actions: [this.saveActionConfig(), this.cancelActionConfig()],
      separateActions: this.separateActions
    });
  }
  saveActionConfig(override) {
    return {
      id: 'modal-save',
      type: 'primary',
      title: this.saveTitle,
      action: () => this.saveAction(),
      isLoading$: this.isLoading$,
      isDisabled$: this.isDisabled$.asObservable(),
      style: {
        textTransform: 'uppercase'
      },
      ...override
    };
  }
  cancelActionConfig(override) {
    return {
      id: 'modal-cancel',
      type: 'default',
      title: this.cancelTitle,
      action: () => this.cancelAction(),
      isDisabled$: this.isLoading$,
      style: {
        textTransform: 'uppercase'
      },
      ...override
    };
  }
  saveAction() {
    this.setLoading(true);
    crmValidateForm(this.formProvider.form);
    this.isFormInvalid().pipe(switchMap(invalid => {
      if (invalid) {
        return this.onInvalid();
      }
      return this.makeRequest();
    })).subscribe({
      next: data => {
        this.onSuccess(data);
        this.setLoading(false);
      },
      error: error => {
        this.onError(error);
        this.setLoading(false);
      }
    });
  }
  isFormInvalid() {
    if (this.formProvider.form.pending) {
      return this.formProvider.form.statusChanges.pipe(map(status => status === 'VALID'), filter(valid => valid), take(1));
    }
    return of(this.formProvider.form.invalid);
  }
  onInvalid() {
    this.setLoading(false);
    return EMPTY;
  }
  onSuccess(data) {
    this.modalRef.close({
      success: true,
      data
    });
  }
  onError(_data) {}
  makeRequest() {
    return of({});
  }
  cancelAction() {
    this.modalRef.destroy({
      success: false
    });
  }
  setLoading(loading) {
    this._isLoading$.next(loading);
    this.formProvider.isLoading = loading;
  }
  static {
    this.ɵfac = function CrmModalFormProvider_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CrmModalFormProvider)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmModalFormProvider,
      factory: CrmModalFormProvider.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmModalFormProvider, [{
    type: Injectable
  }], null, null);
})();
class CrmModalService {
  constructor() {
    this.nzModalService = inject(NzModalService);
  }
  create(modalOptions) {
    return this.nzModalService.create(modalOptions);
  }
  createObservable(modalOptions) {
    return this.create({
      nzFooter: null,
      nzOnCancel: () => {},
      ...modalOptions
    }).afterClose.asObservable();
  }
  createHandlers(modalOptions, handlers) {
    const {
      onSuccess = () => {},
      onDiscard = () => {}
    } = handlers ?? {};
    this.createObservable(modalOptions).subscribe(result => {
      const {
        success,
        data
      } = result ?? {
        success: false
      };
      if (success) {
        onSuccess(data);
      } else {
        onDiscard();
      }
    });
  }
  confirm(modalOptions) {
    return this.nzModalService.confirm(modalOptions);
  }
  info(modalOptions) {
    return this.nzModalService.info(modalOptions);
  }
  success(modalOptions) {
    return this.nzModalService.success(modalOptions);
  }
  error(modalOptions) {
    return this.nzModalService.error(modalOptions);
  }
  warning(modalOptions) {
    return this.nzModalService.warning(modalOptions);
  }
  closeModal(modal) {
    modal.close();
  }
  closeAll() {
    this.nzModalService.closeAll();
  }
  static {
    this.ɵfac = function CrmModalService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CrmModalService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmModalService,
      factory: CrmModalService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmModalService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const crmCreateModalHandlersFn = () => {
  const service = inject(CrmModalService);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return ({
    component,
    modalOptions,
    handlers,
    data
  }) => service.createHandlers({
    nzContent: component,
    nzData: data,
    ...modalOptions
  }, handlers);
};
const crmCreateModalFn$ = () => {
  const fn = crmCreateModalHandlersFn();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (component, data, settings) => {
    const subject = new CrmOnceSubject();
    fn({
      component,
      data,
      modalOptions: {
        nzOnCancel: () => subject.complete(),
        ...settings
      },
      handlers: {
        onSuccess: result => subject.next(result),
        onDiscard: () => subject.complete()
      }
    });
    return subject.asObservable();
  };
};
const crmCreateModalFormData = options => ({
  injector: createEnvironmentInjector([options.modalProvider, options.formProvider, ...(options.additionalProviders ?? [])], options.parentInjector),
  provider: options.modalProvider,
  data: options.data ?? {}
});
const crmCreateModalFormFn$ = () => {
  const createModal$ = crmCreateModalFn$();
  const injector = inject(EnvironmentInjector);
  const translate = inject(CrmTranslateService);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return ({
    modalProvider,
    formProvider,
    additionalProviders,
    title,
    data,
    settings
  }) => createModal$(CrmModalFormComponent, crmCreateModalFormData({
    parentInjector: injector,
    modalProvider,
    formProvider,
    additionalProviders,
    data
  }), {
    nzTitle: title && translate.get(title),
    ...settings
  });
};
const crmProvideModal = () => [NzModalService];

/**
 * @deprecated will be removed in v9
 * @see {crmProvideModal}
 */
class CrmModalModule {
  // TODO: modal config, default modal width, etc.
  static forRoot() {
    return {
      ngModule: CrmModalModule,
      providers: crmProvideModal()
    };
  }
  static {
    this.ɵfac = function CrmModalModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CrmModalModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CrmModalModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmModalModule, [{
    type: NgModule
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CrmModalComponent, CrmModalFormComponent, CrmModalFormProvider, CrmModalModule, CrmModalRefDirective, CrmModalService, crmCreateModalFn$, crmCreateModalFormData, crmCreateModalFormFn$, crmCreateModalHandlersFn, crmProvideModal };
